import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service'
import { ProviderDataService } from './provider-data.service';

import { CustomerService } from './customer.service';

import { PopoverComponent } from './popover/popover.component';
import { CountdownComponent } from './countdown/countdown.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { JwtDecodeService } from '../services/jwt-decode.service';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule
  ],
  declarations: [
  	PopoverComponent,
  	CountdownComponent,
  	TimepickerComponent,
  	DatepickerComponent  ],
  exports:[
	  PopoverComponent,
	  CountdownComponent,
	  TimepickerComponent,
	  DatepickerComponent
	]
})
export class SharedModule { 

	static forRoot(): ModuleWithProviders {
	    return {
	      ngModule: SharedModule,
	      providers: [
	      	ProviderDataService,
	      	CustomerService,
	      	CookieService,
			JwtDecodeService
	      ]
	    }
	}

}
