import { AppComponent } from './app.component';
import { Transition, UIRouter } from '@uirouter/core';
import { ReissueComponent } from './reissue/reissue.component';
import { CookieService } from 'ngx-cookie-service';
import { BackendService } from '@nakedcreativity/wp-backend'
import { environment } from '../environments/environment'
//import { BidsModule } from './bids/bids.module'
// import specific components so that we can route to them
//import { ProviderModule } from '@nakedcreativity/wp-provider';
//import { FormComponent, AdminComponent, ProviderComponent, PlanComponent, ProductsComponent } from '@nakedcreativity/wp-provider'
/**
 * This is the parent state for the entire application.
 *
 * This state's primary purposes are:
 * 1) Shows the outermost chrome (including the navigation and logout for authenticated users)
 * 2) Provide a viewport (ui-view) for a substate to plug into
 */
export function cookieResolve($cookie: CookieService, $backend: BackendService, transition: Transition, router: UIRouter):any {
   
    // return new Promise((resolve, reject)=>{

    //   if(!$cookie.get('membrs')){
    //     window.location.href = environment.membrsLoginPage
    //     reject('No cookie')
    //   }else {

    //     $backend.token.next($cookie.get('membrs'))
    //     if(transition.to().name == 'root'){
          
    //     }
    //     resolve()
    //   }
  
    // })

}

export const rootState = {
  name: 'root',
  url: '/',
  component: AppComponent,
  resolve: [
    {token: 'auth', deps: [CookieService, BackendService, Transition, UIRouter], resolveFn: cookieResolve}
  ]
}

export const appState = {
  name: 'app',
  url: '/:id',
  component: AppComponent,
  resolve: [
    {token: 'auth', deps: [CookieService, BackendService, Transition, UIRouter], resolveFn: cookieResolve}
  ]
};

export const reissueState = {
  name: 'reissue',
  url: '/reissue?token',
  component: ReissueComponent

  // resolve: [
  //   { token: 'returnTo', deps: [Transition], resolveFn: returnTo },
  // ]
}

// export function returnTo ($transition$: Transition): any {
//   if ($transition$.redirectedFrom() != null) {
//     // The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate contacts)
//     // Return to the original attempted target state (e.g., contacts)
//     return $transition$.redirectedFrom().targetState();
//   }

//   const $state = $transition$.router.stateService;

//   // The user was not redirected to the login state; they directly activated the login state somehow.
//   // Return them to the state they came from.
//   if ($transition$.from().name !== '') {
//     return $state.target($transition$.from(), $transition$.params('from'));
//   }

//   // If the fromState's name is empty, then this was the initial transition. Just return them to the home state
//   return $state.target('home');
// }

export const reviewsFutureState = {
  name: 'app.reviews.**',
  url: '/reviews',
  loadChildren: './reviews/reviews.module#ReviewsModule'
  
};

export const providerFutureState = {
  name: 'app.provider.**',
  url: '/provider',
  loadChildren: './provider-loader/provider-loader.module#ProviderLoaderModule'
};

export const bidsFutureState = {
  name: 'app.bids.**',
  url: '/bids',
  loadChildren: './bids/bids.module#BidsModule'
};

export const sellersFutureState = {
  name: 'app.sellers.**',
  url: '/sellers',
  loadChildren: './sellers/sellers.module#SellersModule'
};

export const rewardsFutureState = {
  name: 'app.rewards.**',
  url: '/rewards',
  loadChildren: './rewards/rewards.module#RewardsModule'
};

export const dashboardFutureState = {
  name: 'app.dashboard.**',
  url: '/dashboard',
  loadChildren: './dashboard/dashboard.module#DashboardModule'
};

export const productTypesFutureState = {
  name: 'app.productTypes.**',
  url: '/product-types',
  loadChildren: './all-products/all-products.module#AllProductsModule'
};

export const auditTrailFutureState = {
  name: 'app.auditTrail.**',
  url: '/audit-trail',
  loadChildren: './audit-trail/audit-trail.module#AuditTrailModule'
};

export const leadsFutureState = {
  name: 'app.leads.**',
  url: '/leads',
  loadChildren: './leads/leads.module#LeadsModule'
};

export const leadRequestsFutureState = {
  name: 'app.leadRequests.**',
  url: '/lead-requests',
  loadChildren: './lead-requests/lead-requests.module#LeadRequestsModule'
};

export const creditsFutureState = {
  name: 'app.credits.**',
  url: '/credits',
  loadChildren: './credits/credits.module#CreditsModule'
};

export const formsFutureState = {
  name: 'app.omni-forms.**',
  url: '/forms',
  loadChildren: './omni-forms/omni-forms.module#OmniFormsModule'
};

export const APP_STATES = [
  rootState,
  appState,
  bidsFutureState,
  reissueState,
  sellersFutureState,
  rewardsFutureState,
  //providerState,
  //providerAdminState,
  reviewsFutureState,
  dashboardFutureState,
  productTypesFutureState,
  providerFutureState,
  auditTrailFutureState,
  leadsFutureState,
  creditsFutureState,
  leadRequestsFutureState,
  formsFutureState
];
