import { Injectable } from '@angular/core';
import { BackendService } from "@nakedcreativity/wp-backend"
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UIRouter, Transition } from "@uirouter/angular";
import { CookieService } from 'ngx-cookie-service';
import { environment } from  '../../environments/environment'
import { HttpClient } from '@angular/common/http';

@Injectable()

export class ProviderDataService {

	private _provider: BehaviorSubject<any> = new BehaviorSubject({})
  	private _providerId: any
  	private dateQueryString:string
    private token = localStorage.getItem('membrs')

  	constructor(private backend: BackendService, private router: UIRouter, private cookieService: CookieService, private http: HttpClient) {


      this.backend.token.next(this.token);
      this.backend.decodedToken = this.decode(this.token);
  
      this.backend.decodedToken.subscribe(decodedToken => {
        this.backend.get('provider/'+decodedToken.marketplaceId).subscribe(data=>{
          this._provider.next(data)
        })

      })

      // this.http.get(`${environment.apiEndpoint}provider/1`, {
      //   headers: {
      //     'X-Authorization' : token
      //   }
      // }).subscribe(data => {
      //   debugger
        
      //   this._provider.next(data)})

      // if I don't find a cookie, send to login page
      // to-do if token has expired, send to login page


  	}

    private decode(token){
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    }



  	retrieveProviderConfig(client=null){

      if(client != null){
        this._providerId = client;
      }
      else
      {
      	//this.providerId = 2;
        this._providerId = this.backend.decodedToken.value.marketplaceId
         //this.providerId = this.backend.decode().marketplaceId;
      }




  	}


    generateReport(product,startDate=null,endDate=null){

      if(startDate !== null && endDate !== null){
        this.dateQueryString = '?start='+startDate+'&end='+endDate+'&';
      }else{
        this.dateQueryString = '?';
      }

      var token = this.cookieService.get('membrs');

      window.open( environment.apiEndpoint +'/provider/'+this.providerId+'/search-report/'+product+this.dateQueryString+'authorization='+token , '_blank');

    }


    downloadInvoice(file,invoiceId){

      var token = this.cookieService.get('membrs');

      window.open( environment.apiEndpoint +'provider/'+this.providerId+'/invoice/'+invoiceId+'/download/'+file+'?authorization='+token , '_blank');

    }




    get providerId(){
      return this._provider.value.id
    }

    get provider(){
      return this._provider
    }

    set provider(data){
      this._provider.next(data)
    }

    get providerConfig(){
      return this._provider
    }

    set providerConfig(data){
      this._provider.next(data)
    }

}
