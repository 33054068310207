import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { CookieService } from 'ng2-cookies';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class JwtDecodeService {

  decodedPayload = new Subject()

  constructor(private transition: Transition, private cookieService: CookieService) { }



  decode(){

    this.cookieService.set('membrs',this.transition.params().membrs , 1, '/', environment.cookieDomain, environment.cookieSecure)
    localStorage.setItem('membrs', this.transition.params().membrs);
    let base64Url = this.transition.params().membrs.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

     this.decodedPayload.next(JSON.parse(jsonPayload));

  }
}
