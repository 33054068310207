import { Injector } from '@angular/core';
import { UIRouter, Category } from '@uirouter/core';
import { environment } from '../environments/environment'
import { CookieService } from 'ngx-cookie-service';
import { BackendService } from '@nakedcreativity/wp-backend'
import swal, { SweetAlertType } from 'sweetalert2';
//import { googleAnalyticsHook } from './util/ga';
//import { requiresAuthHook } from './global/auth.hook';


export function routerConfigFn(router: UIRouter, injector: Injector) {
  const transitionService = router.transitionService;
  const stateService = router.stateService;


  let backendService: BackendService = injector.get(BackendService)
  let cookieService: CookieService = injector.get(CookieService)

  stateService.defaultErrorHandler((error)=> {

	if(error.detail == 'Bidding Disabled'){

		swal({   
			title: "Upgrade Plan To Access Bids",
			text: "This feature is only available to providers with credit on account",
			type: "warning",     
			confirmButtonColor: "#149fba",   
			confirmButtonText: "OK", 
		  })

	}

	if(error.detail == 'Reviews Disabled'){

		swal({   
			title: "Upgrade Plan To Access Reviews",
			text: "This feature is only available to providers with credit on account",
			type: "warning",     
			confirmButtonColor: "#149fba",   
			confirmButtonText: "OK", 
		  })

	}
	
  })

  transitionService.onBefore({from: 'app.provider.adminEdit'}, (transition) =>{


  	if(subscription){


  	}else {

	  	backendService.reissue(cookieService.get('membrs'));
	    var subscription = backendService.token.subscribe(result => {
	      if(result && result != cookieService.get('membrs')){

	        cookieService.set('membrs', result, 1, '/', environment.cookieDomain, environment.cookieSecure)
	        subscription.unsubscribe()
	      }
	    }, error => {

	      	subscription.unsubscribe()
	    })
	}

  })


  transitionService.onFinish({}, (transition)=>{
  	window.scrollTo(0,0)
  })

}
