import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

// merge backend and api into a global npm module
import { BackendModule } from '@nakedcreativity/wp-backend';
//import { ProviderModule } from '@nakedcreativity/wp-provider';
import { MessageModule} from './message/message.module'
//import { ApiService } from './api-service/api.service'

import { SharedModule } from './shared/shared.module';

// remove this block
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Http, RequestOptions, HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { ReissueComponent } from './reissue/reissue.component';
import { UIRouterModule, UIView } from '@uirouter/angular';
import { APP_STATES } from './app.states';
import { routerConfigFn } from './router.config';

// environment variables
import { environment } from '../environments/environment';

//sidebar
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [
    AppComponent,
    ReissueComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    BackendModule.forRoot(environment),
    MessageModule.forRoot(),
    UIRouterModule.forRoot({
      states: APP_STATES,
      useHash: false,
      config: routerConfigFn,
    }),
    FormsModule,
    HttpClientModule,
    HttpModule,
    SharedModule.forRoot(),
    NgbModule.forRoot(),
    SharedModule.forRoot(),
    SharedModule,
    ReactiveFormsModule,
  ],
  providers: [
  	{ provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader }
  ],
  bootstrap: [
  	UIView
  ]
})

export class AppModule { }

// export function authHttpServiceFactory(http: Http, options: RequestOptions) {
//   return new AuthHttp(new AuthConfig({
//     noTokenScheme: true,
//     tokenName: 'membrs',
//     tokenGetter: (() => localStorage.getItem('membrs')),
//     headerName: 'X-Authorization'
//   }), http, options);
// }
