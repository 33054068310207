export const environment = {
  production: true,
  apiEndpoint: 'https://api.worryandpeace.com/',
  membrsEndpoint: 'https://staging.memb.rs/api/v1/',
  membrsLoginPage: 'https://providers.peaccce.com/login',
  envName: 'prod',
  apiKey: 'ZSunY2a4hX-T2vQ2Y7rpd-bzy7aZpFL5',
  authMethod: 'jwt',
  stripe: 'pk_live_H1fogmXMVdpuaWjwhp83iuw6',
  customerMarketplace: 'https://customer.marketplace.worryandpeace.com',
  cookieDomain: '.marketplace.peaccce.com',
  cookieSecure: true
};
