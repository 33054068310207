import { Component, OnInit } from '@angular/core';
import { ProviderDataService } from "../shared/provider-data.service";
import { BackendService } from '@nakedcreativity/wp-backend';
import { environment } from  '../../environments/environment'
import { CookieService } from 'ngx-cookie-service';

import swal from 'sweetalert2';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
  //styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {


	public showMobileMenu = false;
  public name = '';
  public avatar = '';
  public adminId = '';

  constructor(private providerDataService: ProviderDataService, private backend: BackendService, private cookieService: CookieService) { 

  
    backend.decodedToken.subscribe(decodedToken =>{ 
      this.name = decodedToken.firstname+' '+decodedToken.surname
      this.avatar = decodedToken.avatar
      this.adminId = decodedToken.adminId
    }) 


  }

  logout() {

    swal({   
      title: "Are you sure you want to logout?",   
      type: "warning",   
      showCancelButton: true,   
      confirmButtonColor: "#149fba",   
      confirmButtonText: "Yes, logout", 
    })
    .then((selection) => {
        
      //API Logout
      this.cookieService.delete('membrs')
      window.location.href = environment.membrsLoginPage

    })
    .catch(swal.noop);
        
  }

  get provider(){
    return this.providerDataService.provider.value
  }

  get customerMarketplace(){
    return environment.customerMarketplace
  }

}
