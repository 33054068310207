import { Component, OnInit } from '@angular/core';
// import { ApiService } from '../api-service/api.service';
import { UIRouter, Transition } from "@uirouter/angular";
import { BackendService } from '@nakedcreativity/wp-backend';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment'


@Component({
  selector: 'app-reissue',
  templateUrl: './reissue.component.html',
  styleUrls: ['./reissue.component.less']
})
export class ReissueComponent implements OnInit{

  public error:string
  private subscription: any
  private subscription2: any

  constructor(private backend: BackendService, private router: UIRouter, private transition: Transition, private cookieService: CookieService) {
      
  }

  ngOnInit(){

    this.backend.reissue(this.transition.params().token);
    this.backend.token.next(this.transition.params().token);
    localStorage.setItem('membrs', this.transition.params().token);
   
    this.backend.decodedToken = this.decode();

    // this.router.stateService.go('app.dashboard', {id: this.backend.decodedToken.marketplaceId});  
  
    this.subscription = this.backend.token.subscribe(result => {
      if(result){
        this.cookieService.set('membrs', result, 1, '/', environment.cookieDomain, environment.cookieSecure)
      }
     }, error => {
      console.log(error)
    })

    this.subscription2 = this.backend.decodedToken.subscribe(result => {
   
      if(result){
        this.router.stateService.go('app.dashboard', {id: result.marketplaceId}).then(() => {window.location.reload()});  
      }
      

    })

  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
    this.subscription2.unsubscribe()
  }

  decode(){
    let token = this.transition.params().token
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }


}
